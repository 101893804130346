.react-datepicker__day--selected{
    background-color: #17a2b8;
}
.react-datepicker__day--in-selecting-range{
    background-color: #17a2b8;
}
.react-datepicker__day--in-range{
    background-color: #17a2b8;
}
.react-datepicker__day--selected:hover{
    background-color: #17a2b8;
}
.react-datepicker__day--in-selecting-range:hover{
    background-color: #17a2b8;
}
.react-datepicker__day--in-range:hover{
    background-color: #17a2b8; 
}
.react-datepicker__month--selecting-range{
    background-color: #17a2b8; 
}
.react-datepicker__day--in-selecting-range{
    background-color: #17a2b8;
}
.react-datepicker__year-text--selected{
    background-color: #17a2b8;
}
.react-datepicker__year-text--in-selecting-range{
    background-color: #17a2b8;
}
.react-datepicker__input-time-container{
    background-color: #17a2b8;
}
.react-datepicker__time{
    background-color: #17a2b8;
}
.react-datepicker__day:hover{ background-color: #17a2b8;} 
/* .react-datepicker__day--001{ background-color: #17a2b8;}  */
.react-datepicker__day--keyboard-selected{ background-color: #17a2b8;}
.react-datepicker__day--selecting-range-start:hover{
    background-color: #17a2b8;
}
.react-datepicker__time-list-item:hover{background-color: #17a2b8;}  
.react-datepicker__time-list-item--selected{background-color: #17a2b8;} 

.react-datepicker__time-container{background-color: #17a2b8;}  
.react-datepicker__time {background-color: #17a2b8;}  
.react-datepicker__time-list-item--selected{background-color: #17a2b8;}  

.react-datepicker__close-icon::after{background-color: #17a2b8;}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #17a2b8;
    color: white;
    font-weight: bold;
  }